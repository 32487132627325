<template>
  <!-- 实验用房预约 -->
  <div class="con_contain">
    <div class="mainpage">
      <div class="head">
        <div class="searchcontain">
          <input class="elinput" placeholder="请输入实验用房" type="text" v-model="keyword" />

          <div class="searchbt" @click="searchbt">搜索</div>
        </div>
      </div>

      <div class="titlecategory">
        <div class="titlecategory-item">
          <div class="item-left">学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;院</div>
          <div class="item-right">
            <div :class="queryParams.college_id === ''
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text'
              " @click.stop="academybtclick('')">全部</div>
            <div :class="queryParams.college_id === item.id
              ? 'item-right-text item-right-text-heightlight'
              : 'item-right-text'
              " @click.stop="academybtclick(item.id)" v-for="(item, index) in collegelist" :key="index">{{ item.name }}
            </div>
          </div>
        </div>
      </div>
      <div class="image-contain">
        <div class="image-item">
          <div class="image-item-item" v-for="(item, index) in openroomlist" :key="index" @click="todetail(item.id)">
            <div class="card-contain">
              <!-- <div class="openroom_img" v-if="item.lab_room_image == null">
                <span>{{ item.name }}</span>
              </div> -->
              <div style="background: aliceblue; text-align: center; height: 100%;">
                <el-image :class="{ 'elimage': item.lab_room_image != null, 'elimage1': item.lab_room_image == null }"
                  :src="item.lab_room_image ? item.lab_room_image : image1" />
              </div>
              <!-- <el-image class="elimage" v-if="item.lab_room_image != null" :src="item.lab_room_image" /> -->
              <div class="card-title">
                <span>{{ item.name }}</span>
                <span v-if="item.lab_room_sn">({{ item.lab_room_sn }})</span>
              </div>
            </div>
          </div>
        </div>
        <div class="elpagination">
          <div class="elpagination-title">共{{ total }}条</div>
          <el-pagination background layout="prev, pager, next" :total="total" :page-size="12"
            :current-page="queryParams.page" @current-change="pageChange"></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getcollegelist } from "@/api/toptype"
import { getOpenRoomList } from "@/api/openroom"
export default {
  name: "mainpage",
  components: {},
  data() {
    return {
      academybtid: "",
      collegelist: [],
      total: 0,
      openroomlist: [],
      keyword: "",
      queryParams: {
        keyword: "",
        college_id: "",
        page: 1,
        per_page: 12,
      },
      image1: require("@/assets/openroom.png"),

    }
  },
  computed: {},
  created() {
    this.getopenroomlist()
    this.getcollegelist({ record_type: 1 }) //record_type 是否获取全部记录 0：分页，1：全部
  },

  methods: {
    todetail(id) {
      this.roomid = id
      this.$router.push({
        path: "/home/openroom/roomdetails",
        query: {
          id: id,
        },
      })
    },
    //页码发生改变时
    pageChange(val) {
      this.queryParams.page = val
      this.getopenroomlist()
    },
    searchbt() {
      this.queryParams.keyword = this.keyword
      this.queryParams.page = 1
      this.getopenroomlist()
    },
    academybtclick(id) {
      this.queryParams.college_id = id
      this.getopenroomlist()
    },
    getcollegelist(params) {
      this.collegelist = []
      const condition = sessionStorage.getItem('college_all')
      if (condition) {
        this.collegelist = JSON.parse(condition)
      } else {
        getcollegelist(params)
          .then((response) => {
            if (response.data && response.data.length > 0) {
              response.data.forEach((item) => {
                this.collegelist.push({
                  id: item.id,
                  name: item.name,
                })
              })
            }
          })
          .catch((error) => { })
      }
    },

    getopenroomlist() {
      this.openroomlist = []
      getOpenRoomList(this.queryParams)
        .then((response) => {
          this.total = response.data.total
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach((item) => {
              this.openroomlist.push({
                id: item.id,
                lab_centre_name: item.lab_centre_name,
                lab_name: item.lab_name,
                lab_room_image: item.lab_room_image,
                lab_room_sn: item.lab_room_sn,
                name: item.name,
              })
            })
          }
        })
        .catch((error) => { })
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 640px) {
  .con_contain {
    display: flex;
    justify-content: center;
    align-items: center;

    .mainpage {
      background: #ffffff;
      width: 100%;

      .head {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        .head-title {
          font-size: 20px;
          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 28px;
            border-radius: 25px;
            padding-left: 20px;
            border: 1px solid #3d84ff;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 32px;
            background: #3d84ff;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }
          }
        }
      }

      .titlecategory {
        margin-top: 30px;
        background: #fbfbfb;
        border-radius: 4px;
        // padding: 30px 10px 10px 30px;

        .titlecategory-item {
          // display: flex;
          // justify-content: flex-start;
          // align-items: flex-start;

          .item-left {
            flex-basis: 80px;
            font-size: 16px;

            font-weight: bold;
            color: #333;
            user-select: none;
            white-space: nowrap;
            height: 35px;
            line-height: 35px;
            margin-left: 10px;
          }

          .item-right {
            flex-grow: 1;
            flex-shrink: 1;
            margin-left: 10px;
            line-height: 25px;

            .item-right-text {
              display: inline-block;
              margin-bottom: 4px;
              margin-right: 25px;
              font-size: 14px;

              font-weight: 400;
              color: #666;
              user-select: none;
              white-space: nowrap;
              cursor: pointer;
            }

            .item-right-text-heightlight {
              color: #3d84ff;
              height: 24px;
              line-height: 24px;
              background: rgba(61, 132, 255, .1);
              border-radius: 12px;
              text-align: center;
              padding: 0 15px;
            }
          }
        }
      }

      .image-contain {
        margin-top: 10px;

        .image-item {
          margin-bottom: 50px;
          height: 100%;
          width: 100%;
          overflow: hidden;
          z-index: 9;
          position: relative;

          .image-item-item {
            margin-bottom: 10px;
            width: calc((100% - 0px) / 2);
            height: 220px;
            float: left;
            padding: 10px 5px 5px 5px;
            box-sizing: border-box;
            cursor: pointer;

            .card-contain {
              background: #ffffff;
              border-radius: 4px;

              .elimage {
                display: block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                display: block;
                width: 100%;
                height: 160px;
              }
              .elimage1 {
                display: block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage1:hover,
              .elimage1:focus,
              .elimage1:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage1 {
                display: block;
                width: 75%;
                height: 160px;
                margin: 0 auto;
              }

              .card-title {
                color: #222222;
                font-size: 15px;
                font-weight: 500;
                margin-top: 15px;
                margin-left: 10px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              .card-footer {
                margin-top: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .icon-title {
                  margin-left: 10px;
                  font-size: 15px;
                  font-weight: 400;
                  color: #666666;
                }

                .icon-text {
                  background-image: url(~@/assets/矩形图标.png);
                  width: 108px;
                  height: 24px;
                  text-align: center;
                  color: #288add;
                }
              }
            }
          }
        }

        .elpagination {
          margin-bottom: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          .elpagination-title {
            margin-right: 4px;
          }
        }

        .openroom_img {
          position: relative;
          z-index: 9999;
          background: #3d84ff;
          width: 100%;
          height: 160px;
          font-size: 24px;
          color: #fff;

          span {
            display: block;
            word-break: break-all;
            text-align: center;
            line-height: 160px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 640px) and (max-width: 1200px) {
  .con_contain {
    display: flex;
    justify-content: center;
    align-items: center;

    .mainpage {
      background: #ffffff;
      width: 100%;

      .head {
        margin-top: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        .head-title {
          font-size: 20px;
          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 28px;
            border-radius: 25px;
            padding-left: 20px;
            border: 1px solid #3d84ff;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 32px;
            background: #3d84ff;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }
          }
        }
      }

      .titlecategory {
        margin-top: 20px;
        background: #fbfbfb;
        border-radius: 4px;
        // padding: 30px 10px 10px 30px;

        .titlecategory-item {
          // display: flex;
          // justify-content: flex-start;
          // align-items: flex-start;

          .item-left {
            flex-basis: 80px;
            font-size: 16px;

            font-weight: bold;
            color: #333;
            user-select: none;
            white-space: nowrap;
            height: 35px;
            line-height: 35px;
            margin-left: 10px;
          }

          .item-right {
            flex-grow: 1;
            flex-shrink: 1;
            margin-left: 10px;
            line-height: 25px;

            .item-right-text {
              display: inline-block;
              margin-bottom: 4px;
              margin-right: 25px;
              font-size: 14px;

              font-weight: 400;
              color: #666;
              user-select: none;
              white-space: nowrap;
              cursor: pointer;
            }

            .item-right-text-heightlight {
              color: #3d84ff;
              height: 24px;
              line-height: 24px;
              background: rgba(61, 132, 255, .1);
              border-radius: 12px;
              text-align: center;
              padding: 0 15px;
            }
          }
        }
      }

      .image-contain {
        margin-top: 10px;

        .image-item {
          margin-bottom: 50px;
          height: 100%;
          width: 100%;
          overflow: hidden;
          z-index: 9;
          position: relative;

          .image-item-item {
            margin-bottom: 10px;
            width: calc((100% - 20px) / 2);
            float: left;
            padding: 10px 5px 5px 5px;
            cursor: pointer;

            .card-contain {
              background: #ffffff;
              border-radius: 4px;

              .elimage {
                display: block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                display: block;
                width: 100%;
                height: 160px;
              }
              .elimage1 {
                display: block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage1:hover,
              .elimage1:focus,
              .elimage1:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage1 {
                display: block;
                width: 75%;
                height: 160px;
                margin: 0 auto;
              }

              .card-title {
                color: #222222;
                font-size: 15px;
                font-weight: 500;
                margin-top: 15px;
                margin-left: 10px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              .card-footer {
                margin-top: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .icon-title {
                  margin-left: 10px;
                  font-size: 15px;
                  font-weight: 400;
                  color: #666666;
                }

                .icon-text {
                  background-image: url(~@/assets/矩形图标.png);
                  width: 108px;
                  height: 24px;
                  text-align: center;
                  color: #288add;
                }
              }
            }
          }
        }

        .elpagination {
          margin-bottom: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          .elpagination-title {
            margin-right: 4px;
          }
        }

        .openroom_img {
          position: relative;
          z-index: 9999;
          background: #3d84ff;
          width: 100%;
          height: 160px;
          font-size: 24px;
          color: #fff;

          span {
            display: block;
            word-break: break-all;
            text-align: center;
            line-height: 160px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  .con_contain {
    display: flex;
    justify-content: center;
    align-items: center;

    .mainpage {
      background: #ffffff;
      width: 1760px;

      .head {
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .head-title {
          font-size: 20px;
          font-weight: 400;
          color: #288add;
          user-select: none;
          white-space: nowrap;
        }

        .searchcontain {
          position: relative;

          .elinput {
            width: 260px;
            height: 28px;
            border-radius: 25px;
            padding-left: 20px;
            border: 1px solid #3d84ff;

            &:focus {
              outline: none;
            }
          }

          .searchbt {
            position: absolute;
            top: 0px;
            right: 0px;
            margin-left: 17px;
            width: 85px;
            height: 32px;
            background: #3d84ff;
            border-radius: 25px;
            font-size: 15px;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
            user-select: none;

            &:active {
              background: #26b7ffa9;
            }
          }
        }
      }

      .titlecategory {
        margin-top: 20px;
        background: #fbfbfb;
        border-radius: 4px;
        padding: 30px 10px 30px 30px;

        .titlecategory-item {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;

          .item-left {
            flex-basis: 80px;
            font-size: 16px;

            font-weight: bold;
            color: #333;
            user-select: none;
            white-space: nowrap;
          }

          .item-right {
            flex-grow: 1;
            flex-shrink: 1;
            margin-left: 15px;

            .item-right-text {
              display: inline-block;
              margin-bottom: 4px;
              margin-right: 30px;
              font-size: 14px;

              font-weight: 400;
              color: #666;
              user-select: none;
              white-space: nowrap;
              cursor: pointer;
            }

            .item-right-text-heightlight {
              color: #3d84ff;
              height: 24px;
              line-height: 24px;
              background: rgba(61, 132, 255, .1);
              border-radius: 12px;
              text-align: center;
              padding: 0 15px;
            }
          }
        }
      }

      .image-contain {
        margin-top: 10px;

        .image-item {
          margin-bottom: 50px;
          height: 100%;
          width: 100%;
          overflow: hidden;

          .image-item-item {
            margin-bottom: 10px;
            width: calc((100% - 120px) / 6);
            float: left;
            padding: 10px;
            cursor: pointer;

            .card-contain {
              background: #ffffff;
              border-radius: 4px;

              .elimage {
                display: block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage:hover,
              .elimage:focus,
              .elimage:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage {
                display: block;
                width: 100%;
                height: 160px;
              }
              .elimage1 {
                display: block;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-property: transform;
                transition-property: transform;
                -webkit-transform: translateZ(0);
                transform: translateZ(0);
                box-shadow: 0 0 1px rgba(0, 0, 0, 0);
              }

              .elimage1:hover,
              .elimage1:focus,
              .elimage1:active {
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }

              .elimage1 {
                display: block;
                width: 75%;
                height: 160px;
                margin: 0 auto;
              }

              .card-title {
                color: #222222;
                font-size: 15px;
                font-weight: 500;
                margin-top: 15px;
                margin-left: 10px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              .card-footer {
                margin-top: 28px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .icon-title {
                  margin-left: 10px;
                  font-size: 15px;
                  font-weight: 400;
                  color: #666666;
                }

                .icon-text {
                  background-image: url(~@/assets/矩形图标.png);
                  width: 108px;
                  height: 24px;
                  text-align: center;
                  color: #288add;
                }
              }
            }
          }
        }

        .elpagination {
          margin-bottom: 40px;
          display: flex;
          justify-content: center;
          align-items: center;

          .elpagination-title {
            margin-right: 4px;
          }
        }

        .openroom_img {
          position: relative;
          z-index: 9999;
          background: #3d84ff;
          width: 100%;
          height: 160px;
          font-size: 24px;
          color: #fff;

          span {
            display: block;
            word-break: break-all;
            text-align: center;
            line-height: 160px;
          }
        }
      }
    }
  }
}
</style>
